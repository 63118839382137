<template>
  <ul class="app-search-list">
    <li v-for="(item, index) in filterItems.slice(0, 50)" :key="index" class="app-search-item">
      <div class="app-search-elem">
        <div class="app-search-elem-title" @click="$emit('select', item)">{{ item }}</div>
      </div>
    </li>
  </ul>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'

const props = defineProps({
  keyword: {
    type: String,
    default: ''
  },
  list: {
    type: Array as PropType<string[]>,
    default: () => []
  }
})
defineEmits(['select'])
const filterItems = computed(() => {
  const listSet = Array.from(new Set(props.list))
  return props.keyword.length > 0
    ? listSet.filter((item) => item.toLowerCase().includes(props.keyword.toLowerCase()))
    : listSet
})
</script>
<style lang="scss" scoped>
.app-search-elem-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media not all and (min-width: 1024px) {
  .app-search-list {
    height: 85%;
    overflow: auto;
    &::-webkit-scrollbar-thumb {
      background-color: #4d5158;
      border-radius: 0;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
  }
  .app-search-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-size: 32px;
    color: #fff;
    &.selected {
      background-color: #2b2d32;
    }
  }
  .app-search-elem {
    width: 100%;
    height: 100%;
    padding: 0 42px;
  }
}
@media screen and (min-width: 1024px) {
  .app-search-list {
    height: 94%;
    overflow: auto;
    &::-webkit-scrollbar-thumb {
      background-color: #4d5158;
      border-radius: 0;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
  }
  .app-search-item {
    display: flex;
    align-items: center;
    width: calc(100% - 32px);
    margin: 0 auto;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #2b2d32;
    }
  }
  .app-search-elem {
    width: 100%;
    height: 100%;
    padding: 0 8px;
  }
}
</style>
