<template>
  <div ref="resultRef" class="app-search-result">
    <ul v-if="renderList.length > 0 && !loading" :class="['app-search-list']" @click="$emit('close')">
      <li v-for="(item, index) in renderList" :key="index" :class="['app-search-item']">
        <nuxt-link class="app-search-elem" :to="formatUrl(item)" target="_blank">
          <div class="app-search-elem-title" v-html="item.title"></div>
          <div class="app-search-elem-path">{{ item.attribution }}</div>
          <div class="app-search-elem-desc" v-html="formatHtml(item.content)"></div>
        </nuxt-link>
      </li>
    </ul>
    <div v-if="!renderList.length && !loading" class="app-search-empty">
      <img class="app-search-empty-img" src="@/assets/images/search_empty.png" alt="" />
      <span class="app-search-empty-text">{{ formatTransify('UGC_OFFICIAL_WEBSITE_SEARCH_NORESULTS') }}</span>
    </div>
    <div v-show="loading" class="app-search-loading">
      <svg class="app-search-loading-icon" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M33.1071 0.642857C33.1071 0.288281 33.3944 0 33.75 0H38.25C38.6045 0 38.8928 0.288281 38.8928 0.642857V17.3571C38.8928 17.7127 38.6056 18 38.25 18H33.75C33.3944 18 33.1071 17.7127 33.1071 17.3571V0.642857Z"
          fill="#C8CCD3"
        />
        <path
          d="M15.75 6.82434C15.5722 6.51698 15.6777 6.12423 15.985 5.94644L19.8824 3.69644C20.1897 3.51865 20.5825 3.62412 20.7603 3.93149L29.1174 18.4068C29.2952 18.7142 29.1897 19.1069 28.8824 19.2847L24.985 21.5347C24.8374 21.6201 24.6626 21.6432 24.4969 21.599C24.3321 21.5548 24.1915 21.4473 24.1061 21.2997L15.749 6.82434H15.75Z"
          fill="#C8CCD3"
          fill-opacity="0.12"
        />
        <path
          d="M3.93149 20.7604C3.62412 20.5826 3.51865 20.1899 3.69644 19.8825L5.94644 15.9852C6.12423 15.6778 6.51698 15.5723 6.82434 15.7501L21.2997 24.1073C21.4473 24.1926 21.5548 24.3333 21.599 24.498C21.6432 24.6627 21.6201 24.8385 21.5347 24.9852L19.2847 28.8825C19.1069 29.1899 18.7142 29.2953 18.4068 29.1175L3.93149 20.7604Z"
          fill="#C8CCD3"
          fill-opacity="0.2"
        />
        <path
          d="M0.642857 38.8928C0.288281 38.8928 0 38.6055 0 38.25V33.75C0 33.3944 0.288281 33.1071 0.642857 33.1071H17.3571C17.7127 33.1071 18 33.3944 18 33.75V38.25C18 38.6045 17.7127 38.8928 17.3571 38.8928H0.642857Z"
          fill="#C8CCD3"
          fill-opacity="0.28"
        />
        <path
          d="M56.25 6.82434C56.4278 6.51698 56.3223 6.12423 56.015 5.94644L52.1176 3.69644C51.8103 3.51865 51.4165 3.62412 51.2397 3.93149L42.8826 18.4068C42.7048 18.7142 42.8103 19.1069 43.1176 19.2847L47.015 21.5347C47.1626 21.6201 47.3374 21.6432 47.5031 21.599C47.6679 21.5548 47.8085 21.4473 47.8939 21.2997L56.251 6.82434H56.25Z"
          fill="#C8CCD3"
          fill-opacity="0.92"
        />
        <path
          d="M68.0685 20.7604C68.3759 20.5826 68.4813 20.1899 68.3035 19.8825L66.0535 15.9852C65.8758 15.6778 65.483 15.5723 65.1756 15.7501L50.7003 24.1073C50.5527 24.1926 50.4452 24.3333 50.401 24.498C50.3568 24.6627 50.3799 24.8385 50.4653 24.9852L52.7153 28.8825C52.892 29.1899 53.2858 29.2953 53.5932 29.1175L68.0675 20.7604H68.0685Z"
          fill="#C8CCD3"
          fill-opacity="0.84"
        />
        <path
          d="M71.3571 38.8928C71.7127 38.8928 72 38.6055 72 38.25V33.75C72 33.3944 71.7117 33.1071 71.3571 33.1071H54.6429C54.2883 33.1071 54 33.3944 54 33.75V38.25C54 38.6045 54.2873 38.8928 54.6429 38.8928H71.3571Z"
          fill="#C8CCD3"
          fill-opacity="0.76"
        />
        <path
          d="M33.1071 71.3571C33.1071 71.7127 33.3944 72 33.75 72H38.25C38.6045 72 38.8928 71.7117 38.8928 71.3571V54.6429C38.8928 54.2873 38.6056 54 38.25 54H33.75C33.3944 54 33.1071 54.2873 33.1071 54.6429V71.3571Z"
          fill="#C8CCD3"
          fill-opacity="0.52"
        />
        <path
          d="M15.75 65.1755C15.5722 65.4829 15.6777 65.8756 15.985 66.0534L19.8824 68.3034C20.1897 68.4812 20.5825 68.3757 20.7603 68.0683L29.1174 53.594C29.2952 53.2867 29.1897 52.8929 28.8824 52.7161L24.985 50.4661C24.8374 50.3807 24.6626 50.3576 24.4969 50.4018C24.3321 50.446 24.1915 50.5535 24.1061 50.7012L15.749 65.1765L15.75 65.1755Z"
          fill="#C8CCD3"
          fill-opacity="0.44"
        />
        <path
          d="M3.93149 51.2397C3.62412 51.4175 3.51865 51.8103 3.69644 52.1176L5.94644 56.015C6.12423 56.3223 6.51698 56.4278 6.82434 56.25L21.2997 47.8929C21.4473 47.8075 21.5548 47.6669 21.599 47.5021C21.6432 47.3374 21.6201 47.1616 21.5347 47.015L19.2847 43.1176C19.1069 42.8103 18.7142 42.7048 18.4068 42.8826L3.93149 51.2397Z"
          fill="#C8CCD3"
          fill-opacity="0.36"
        />
        <path
          d="M56.25 65.1755C56.4278 65.4829 56.3223 65.8756 56.015 66.0534L52.1176 68.3034C51.8103 68.4812 51.4165 68.3757 51.2397 68.0683L42.8826 53.594C42.7048 53.2867 42.8103 52.8929 43.1176 52.7161L47.015 50.4661C47.1626 50.3807 47.3374 50.3576 47.5031 50.4018C47.6679 50.446 47.8085 50.5535 47.8939 50.7012L56.251 65.1765L56.25 65.1755Z"
          fill="#C8CCD3"
          fill-opacity="0.6"
        />
        <path
          d="M68.0685 51.2397C68.3759 51.4175 68.4813 51.8103 68.3035 52.1176L66.0535 56.015C65.8758 56.3223 65.483 56.4278 65.1756 56.25L50.7003 47.8929C50.5527 47.8075 50.4452 47.6669 50.401 47.5021C50.3568 47.3374 50.3799 47.1616 50.4653 47.015L52.7153 43.1176C52.892 42.8103 53.2858 42.7048 53.5932 42.8826L68.0675 51.2397H68.0685Z"
          fill="#C8CCD3"
          fill-opacity="0.68"
        />
      </svg>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'
import { SearchGlobalData } from '~/server/types'

const store = useStore()
const { formatTransify } = store
const { config } = storeToRefs(store)
const page = ref(1)
const pageCounts = ref(25)
const resultRef = ref<HTMLElement>()
const props = defineProps({
  list: {
    type: Array as PropType<SearchGlobalData[]>,
    default: () => []
  },
  loading: Boolean,
  currentCategory: {
    type: Number,
    default: 0
  }
})
defineEmits(['close'])
const { $markdown } = useNuxtApp()
const renderList = computed(() => {
  const startIndex = (page.value - 1) * pageCounts.value
  const endIndex = startIndex + pageCounts.value
  const currentList =
    props.currentCategory === 0
      ? props.list
      : props.list.filter((item) => Number(item.article_type) === props.currentCategory)
  return currentList.slice(0, endIndex)
})
const formatMarkdown = (content: string) => {
  return content
    ?.replace(/```([\s\S]*?)```/g, function (_, p1) {
      return p1
    }) // markdown-it 不支持加粗、斜体、斜体加粗的结束符前有特殊字符，会导致解析错误，为避免此类情况，判断此类语法直接转化成html
    .replace(/(\*\*\*|\*\*|\*)(.*?)(\*\*\*|\*\*|\*)/g, function (_: any, _p1: string, p2) {
      return `${p2}`
    })
}
const formatHtml = (content: string) => {
  // Warn：直接替换<>标签格式 会将代码块内的类似的<>也替换掉，该用去掉markdown格式的处理
  // const html = $markdown.render(formatMarkdown(content) || '').replace(/<(?!\/?em)[^<>]*>/g, '')
  return $markdown.render(formatMarkdown(content) || '').replace(/<(?!\/?em)[^<>]*>/g, '')
}
const formatUrl = (result: SearchGlobalData) => {
  const { p4_id: p4, article_type: type, lang_id: langId } = result
  const lang = config.value.lang_list.find((item) => item.id === Number(langId))?.lang || 'en'
  switch (Number(type)) {
    case 1:
      return `/${lang}/docs/${p4}`
    case 2:
      return `/${lang}/tutorial/${p4}`
    default:
      return `/${lang}/`
  }
}
const handleScrollEnd = () => {
  if (resultRef.value) {
    const scrollHeight = resultRef.value.scrollHeight
    const scrollTop = resultRef.value.scrollTop
    const clientHeight = resultRef.value.clientHeight
    const delta = 100
    if (scrollTop + clientHeight >= scrollHeight - delta) {
      page.value++
    }
  }
}
watch(
  () => props.loading,
  (val) => {
    if (!val) {
      resultRef.value && (resultRef.value.scrollTop = 0)
    }
  }
)

watch(
  () => props.currentCategory,
  () => {
    resultRef.value && (resultRef.value.scrollTop = 0)
  }
)

onMounted(() => {
  resultRef.value?.addEventListener('scroll', handleScrollEnd)
})
onUnmounted(() => {
  resultRef.value?.removeEventListener('scroll', handleScrollEnd)
})
</script>
<style lang="scss" scoped>
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.app-search-elem-title,
.app-search-elem-desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media not all and (min-width: 1024px) {
  .app-search-result {
    height: 85%;
    overflow: auto;
    &::-webkit-scrollbar-thumb {
      background-color: #4d5158;
      border-radius: 0;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
  }
  .app-search-list {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    &.hidden {
      opacity: 0;
    }
  }
  .app-search-item {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 80px;
    font-size: 32px;
    color: #fff;
    &.selected {
      background-color: #2b2d32;
    }
  }
  .app-search-elem {
    width: 100%;
    height: 100%;
    padding: 20px 42px;
    &:deep(em) {
      font-weight: bold;
      color: #fff;
      font-style: normal;
    }
  }
  .app-search-elem-path {
    font-size: 28px;
    line-height: 35px;
    color: #acafb5;
    margin: 10px 0;
  }
  .app-search-elem-desc {
    font-size: 28px;
    line-height: 36px;
    color: #5f6166;
  }
  .app-search-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 72px;
    height: 72px;
    margin-left: -36px;
    &-icon {
      width: 100%;
      height: 100%;
      animation: rotate 2s linear infinite;
    }
  }
  .app-search-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &-img {
      width: 205px;
      height: 173px;
    }
    &-text {
      margin-top: 4px;
      font-size: 28px;
      color: #7a7e85;
    }
  }
}
@media screen and (min-width: 1024px) {
  .app-search-result {
    height: 94%;
    overflow: auto;
    padding-bottom: 20px;
    &::-webkit-scrollbar-thumb {
      background-color: #4d5158;
      border-radius: 0;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
  }
  .app-search-item {
    display: flex;
    align-items: center;
    width: calc(100% - 32px);
    margin: 0 auto;
    min-height: 34px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #2b2d32;
    }
  }
  .app-search-elem {
    width: 100%;
    height: 100%;
    padding: 8px;
    &:deep(em) {
      font-weight: bold;
      color: #fff;
      font-style: normal;
    }
  }
  .app-search-elem-path {
    color: #acafb5;
    margin: 4px 0;
  }
  .app-search-elem-desc {
    color: #5f6166;
    line-height: 20px;
  }
  .app-search-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 34px;
    height: 34px;
    margin-left: -17px;
    &-icon {
      width: 100%;
      height: 100%;
      animation: rotate 2s linear infinite;
    }
  }
  .app-search-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &-img {
      width: 112px;
      height: 94px;
    }
    &-text {
      margin-top: 2px;
      font-size: 16px;
      color: #7a7e85;
    }
  }
}
</style>
