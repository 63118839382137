import { SearchGlobalApi } from '~/server/types'

export const useSearchStore = defineStore('Search', () => {
  const { config } = storeToRefs(useStore())
  const searchTypes = computed(() => {
    let types: number[] = []
    if (config.value.footer.search_status) {
      if (config.value.footer.search_terms_documents && config.value.footer.search_terms_tutorial) {
        types = [1, 2]
      } else if (config.value.footer.search_terms_documents) {
        types = [1]
      } else if (config.value.footer.search_terms_tutorial) {
        types = [2]
      }
    }
    return types
  })
  async function fetchSearchData(keyword: string) {
    let res: SearchGlobalApi
    try {
      const result = await $fetch(`/proxy/api/search`, {
        method: 'POST',
        body: {
          search_type: searchTypes.value,
          keyword,
          lang_id: config.value.id
        }
      })
      res = result as SearchGlobalApi
    } catch (error) {
      res = {
        status: 'error',
        msg: '',
        data: {
          data: [],
          count: 0
        }
      }
    }
    return res
  }

  return {
    searchTypes,
    fetchSearchData
  }
})
