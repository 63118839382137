<template>
  <div class="app-search-ctx">
    <button :class="['app-search-btn', searchBtnClass]" @click="showSearchCtx">
      <svg class="app-search-btn-icon" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.5234 11.5234C9.42429 13.6225 6.02103 13.6225 3.92196 11.5234C1.82289 9.42435 1.82289 6.02109 3.92196 3.92202C6.02103 1.82295 9.42429 1.82295 11.5234 3.92202C13.6224 6.02109 13.6224 9.42435 11.5234 11.5234ZM11.8765 13.202C9.18193 15.2501 5.32136 15.0441 2.8613 12.5841C0.176443 9.89922 0.176443 5.54621 2.8613 2.86136C5.54615 0.176505 9.89916 0.176505 12.584 2.86136C15.0439 5.32128 15.25 9.18155 13.2023 11.8761L17.6666 16.3404L16.3408 17.6662L11.8765 13.202Z"
        />
      </svg>
    </button>
    <ClientOnly>
      <Transition name="tran-fadeIn">
        <div v-show="searchShow" :class="['app-search-overlay']" @click="hideSearchCtx"></div>
      </Transition>
      <div :class="['app-search-inner', searchShow && 'show']">
        <div class="app-search-top">
          <div class="app-search">
            <input
              ref="inputRef"
              v-model="model"
              class="app-search-input"
              :placeholder="placeholder"
              @input="onInput"
            />
            <svg
              v-show="!searching"
              class="app-search-icon"
              viewBox="0 0 18 18"
              xmlns="http://www.w3.org/2000/svg"
              @click="search"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.5234 11.5234C9.42429 13.6225 6.02103 13.6225 3.92196 11.5234C1.82289 9.42435 1.82289 6.02109 3.92196 3.92202C6.02103 1.82295 9.42429 1.82295 11.5234 3.92202C13.6224 6.02109 13.6224 9.42435 11.5234 11.5234ZM11.8765 13.202C9.18193 15.2501 5.32136 15.0441 2.8613 12.5841C0.176443 9.89922 0.176443 5.54621 2.8613 2.86136C5.54615 0.176505 9.89916 0.176505 12.584 2.86136C15.0439 5.32128 15.25 9.18155 13.2023 11.8761L17.6666 16.3404L16.3408 17.6662L11.8765 13.202Z"
              />
            </svg>
            <svg
              v-show="searching"
              class="app-search-clear"
              viewBox="0 0 17 17"
              xmlns="http://www.w3.org/2000/svg"
              @click="clearSearchValue"
            >
              <path
                d="M1.15906 0L0 1.15907L7.34094 8.50001L0.386688 15.4543L1.54575 16.6133L8.5 9.65907L15.4543 16.6133L16.6133 15.4543L9.65906 8.50001L17 1.15907L15.8409 0L8.5 7.34094L1.15906 0Z"
              />
            </svg>
          </div>
          <button class="app-search-return" @click="hideSearchCtx">
            <svg class="app-search-return-icon" viewBox="0 0 26 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 3.81441L0 0H4.65638L26 20.0189L21.6361 24.1131L0 3.81441Z" fill="#FFBA00" />
              <path
                d="M17.3012 28.164L4.68543 40H0.00611383L0.00611383 36.234L12.9545 24.0914L17.3012 28.164Z"
                fill="#FFBA00"
              />
            </svg>
          </button>
        </div>
        <div :class="['app-search-result-inner']">
          <div class="app-search-result-title">
            <span class="app-search-result-text">{{ searchResultText }}</span>
            <button v-show="!searching && searchHistory.length > 0" class="app-search-del" @click="clearHistory">
              <svg class="app-search-del-icon" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_36_1669)">
                  <path d="M5.5 3V13H14.5V3H5.5Z" stroke="#BABEC5" stroke-width="1.5" />
                  <path d="M8.66663 6V10" stroke="#BABEC5" stroke-width="1.5" />
                  <path d="M11.3334 6V10" stroke="#BABEC5" stroke-width="1.5" />
                  <path d="M4 3H16" stroke="#BABEC5" stroke-width="1.5" />
                  <path d="M7.5 3L8.52781 1H11.4928L12.5 3H7.5Z" stroke="#BABEC5" stroke-width="1.5" />
                </g>
                <defs>
                  <filter
                    id="filter0_d_36_1669"
                    x="0"
                    y="0.25"
                    width="20"
                    height="21.5"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_36_1669" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_36_1669" result="shape" />
                  </filter>
                </defs>
              </svg>
            </button>
            <BaseSeachCategory
              v-show="searching && searchResults.length"
              v-model="currentCategory"
              :categories="searchTypes"
            />
          </div>
          <BaseSeachHistory v-show="!searching" :list="searchHistory" @select="selectFromHistory" />
          <BaseSeachResult
            v-show="searching"
            :loading="searchLoading"
            :list="searchResults"
            :current-category="currentCategory"
          />
        </div>
      </div>
    </ClientOnly>
  </div>
</template>
<script lang="ts" setup>
import { SearchGlobalData } from '~/server/types'

defineProps({
  placeholder: {
    type: String,
    default: 'Search'
  },
  searchBtnClass: {
    type: String,
    default: ''
  }
})
const store = useStore()
const { formatTransify } = store
const searchStore = useSearchStore()
const { fetchSearchData } = searchStore
const { searchTypes } = storeToRefs(searchStore)
const model = defineModel<string>({ default: '' }) // search key word
const inputRef = ref<HTMLInputElement>()
const searching = ref(false)
const currentCategory = ref(0)
const searchShow = ref(false)
const searchHistory = ref<string[]>([])
const searchLoading = ref(false)
const searchResults = ref<SearchGlobalData[]>([])
const searchResultCounts = ref(0)
const showSearchCtx = () => {
  searchShow.value = true
  inputRef.value?.focus()
  document.body.classList.add('ban-scroll')
}
const hideSearchCtx = () => {
  searchShow.value = false
  model.value = ''
  searching.value = false
  inputRef.value?.blur()
  document.body.classList.remove('ban-scroll')
}
const onInput = () => {
  searching.value = searchResultCounts.value > 0
}

const selectFromHistory = (item: string) => {
  model.value = item
  search()
}
const clearHistory = () => {
  searchHistory.value = []
  storage.removeItem('searchHistory')
}
const initHistory = () => {
  const history = JSON.parse(storage.getItem('searchHistory') || '[]').slice(0, 50)
  searchHistory.value = Array.from(new Set(history))
}
const search = async () => {
  const searchValue = model.value.trim()
  if (searchValue === '') return false
  searchLoading.value = true
  searching.value = true
  const res = await fetchSearchData(searchValue)
  searchResults.value = res.data?.data || []
  searchResultCounts.value = res.data?.count || 0
  searchHistory.value = searchHistory.value.slice(0, 49)
  searchHistory.value.unshift(searchValue)
  setTimeout(() => {
    searchLoading.value = false
  }, 300)
}
const clearSearchValue = () => {
  model.value = ''
  searching.value = false
}
const searchResultText = computed(() => {
  if (searching.value) {
    return formatTransify('UGC_OFFICIAL_WEBSITE_SEARCH_RESULTS')
  } else {
    return formatTransify('UGC_OFFICIAL_WEBSITE_SEARCH_RECENT')
  }
})

watch(
  () => searchHistory.value,
  (val) => {
    const newHistory = Array.from(new Set(val))
    storage.setItem('searchHistory', JSON.stringify(newHistory))
  },
  { deep: true }
)
onMounted(async () => {
  await nextTick()
  inputRef.value?.addEventListener('keydown', (event) => {
    if (event.key === 'Enter') {
      search()
    }
  })
  initHistory()
})
</script>
<style scoped lang="scss">
/* --- Search --- */
.app-search {
  position: relative;
  width: 538px;
  flex-shrink: 0;
}

.app-search-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  cursor: pointer;
  fill: #7a7e85;
}
.app-search-input {
  width: 100%;
  height: 40px;
  padding: 8px 34px 8px 14px;
  background-color: #363a41;
  border: 1px solid #4d5158;
  color: #fff;
  transition: 0.3s;
  border-radius: 0;
  caret-color: #fff;
  &:focus {
    border-color: #c8ccd3;
  }
}
.app-search-clear {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  width: 17px;
  height: 17px;
  cursor: pointer;
  fill: #7a7e85;
  transition: 0.3s;
  &:hover {
    fill: #fff;
  }
}
.app-search-result-text {
  width: 63%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media not all and (hover: none) {
  .app-search-icon {
    &:hover {
      fill: #fff;
    }
  }
}
@media not all and (min-width: 1024px) {
  .app-search-overlay {
    display: none;
  }
  .app-search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #26282d;
  }
  .app-search-btn-icon {
    width: 36px;
    height: 36px;
    fill: #c8ccd3;
  }
  .app-search-inner {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    padding: 40px 0;
    background-color: #202227;
    display: flex;
    flex-direction: column;
    transform: translate(100%, 0);
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;
    &.show {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  .app-search-top {
    display: flex;
    justify-content: center;
    padding: 0 42px;
    margin-bottom: 40px;
  }
  .app-search {
    flex: 1;
    width: auto;
    margin-right: 20px;
    font-size: 28px;
  }
  .app-search-input {
    height: 72px;
  }
  .app-search-icon {
    width: 32px;
    height: 32px;
    right: 24px;
  }
  .app-search-clear {
    width: 30px;
    height: 30px;
    right: 24px;
  }
  .app-search-return {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-radius: 0;
    background: linear-gradient(135deg, #131313 calc(100% - 0.625rem), transparent 0.625rem);
  }
  .app-search-return-icon {
    width: 26px;
    height: 40px;
  }
  .app-search-result-inner {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .app-search-result-title {
    display: flex;
    justify-content: space-between;
    padding: 0 42px;
    font-size: 30px;
    margin-bottom: 30px;
    color: #babec5;
  }
  .app-search-del {
    width: 25px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1.5);
  }
  .app-search-del-icon {
    width: 20px;
    height: 22px;
  }
}
@media screen and (min-width: 1024px) {
  //.app-search-btn,
  //.app-search-return {
  //  display: none;
  //}
  .app-search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #26282d;
  }
  .app-search-btn-icon {
    width: 18px;
    height: 18px;
    fill: #c8ccd3;
  }
  .app-search-inner {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
    width: 570px;
    height: 100%;
    padding: 32px 0;
    background-color: #202227;
    display: flex;
    flex-direction: column;
    transform: translate(100%, 0);
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;
    &.show {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  .app-search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .app-search-result-inner {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .app-search-result-title {
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    font-size: 14px;
    color: #babec5;
  }
  .app-search-del {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    //transform: scale(1.5);
  }
  .app-search-del-icon {
    width: 20px;
    height: 22px;
  }
  .app-search-top {
    display: flex;
    justify-content: center;
    padding: 0 16px;
  }
  .app-search {
    flex: 1;
    width: auto;
    margin-right: 22px;
  }
  .app-search-return {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 0;
    background: linear-gradient(135deg, #131313 calc(100% - 0.625rem), transparent 0.625rem);
  }
  .app-search-return-icon {
    width: 14px;
    height: 22px;
  }
}
</style>
