<template>
  <div v-clickout="close" :class="['com-menu-container', menuBtnClass]">
    <CommonButton :class="['com-menu-button', { open: open }]" @click="toggle">
      <svg v-show="!open" class="com-menu-icon" viewBox="0 0 60 60" fill="#C8CCD3" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 16H44V18H16V16Z" fill="#C8CCD3" />
        <path d="M16 29H44V31H16V29Z" fill="#C8CCD3" />
        <path d="M16 42H44V44H16V42Z" fill="#C8CCD3" />
      </svg>

      <svg
        v-show="open"
        :class="['com-menu-close', { open: open }]"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.414062 28.2843L28.6983 2.6226e-05L30.1125 1.41424L1.82828 29.6985L0.414062 28.2843Z"
          fill="#C8CCD3"
        />
        <path
          d="M1.41406 0.284302L29.6983 28.5686L28.2841 29.9828L-0.000151038 1.69852L1.41406 0.284302Z"
          fill="#C8CCD3"
        />
      </svg>
    </CommonButton>
    <div :class="['com-menu', { open: open }, className]" @click="close">
      <ul class="com-menu-list">
        <li v-for="item in list" :key="item.id" class="com-menu-item">
          <nuxt-link
            v-if="!hasMenuChildren(item)"
            class="com-menu-item-link"
            :to="getRoutePath(item.link)"
            :target="item.is_new_tab ? '_blank' : '_self'"
            @click="clickSelf(item)"
          >
            <span class="com-menu-name">
              {{ item.name }}
            </span>
            <svg
              v-if="item.is_outside_website"
              class="com-nav-outside"
              viewBox="0 0 14 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.2562 0H5.09474V1.41758H8.55117L2 7.7884V10L9.82854 2.68594V6.11798H11.2562V0Z" />
            </svg>
          </nuxt-link>
          <div
            v-if="hasMenuChildren(item) && submenu"
            :class="['com-menu-item-link', { open: subMenuShowId === item.id }]"
            @click.stop="toggleSubMenu(item.id)"
          >
            <span class="com-menu-name">
              {{ item.name }}
            </span>
            <svg
              v-if="hasMenuChildren(item)"
              class="com-nav-arrow"
              viewBox="0 0 14 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7163 8.88892L7.14894 3.70373L2.38298 8.88892L0 8.88892L7.14894 1.11114L14 8.88892L11.7163 8.88892Z"
              />
            </svg>
          </div>
          <template v-if="hasMenuChildren(item) && submenu">
            <transition name="tran-fadeIn">
              <div v-show="subMenuShowId === item.id" v-clickout="closeSubMenu" :class="['com-sub-menu', subMenuType]">
                <ul class="com-sub-menu-list">
                  <li v-for="sub in item.children" :key="sub.id" class="com-menu-item">
                    <nuxt-link
                      class="com-menu-item-link"
                      :to="getRoutePath(sub.link)"
                      :target="sub.is_new_tab ? '_blank' : '_self'"
                      @click="clickSelf(sub)"
                    >
                      {{ sub.name }}
                      <svg
                        v-if="sub.is_outside_website"
                        class="com-nav-outside"
                        viewBox="0 0 14 10"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.2562 0H5.09474V1.41758H8.55117L2 7.7884V10L9.82854 2.68594V6.11798H11.2562V0Z" />
                      </svg>
                    </nuxt-link>
                  </li>
                </ul>
              </div>
            </transition>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'

interface MenuConfig {
  id: number
  name: string
  link: string
  is_new_tab: 0 | 1 // 新标签页打开
  is_outside_website: 0 | 1 // 外链，外链为true，才会启用新标签页打开
  children?: MenuConfig[]
}
const props = defineProps({
  menuBtnClass: null as unknown as PropType<unknown>,
  className: null as unknown as PropType<unknown>,
  open: {
    type: Boolean,
    default: false
  },
  subMenuOpen: {
    type: Boolean,
    default: false
  },
  submenu: {
    type: Boolean,
    default: false
  },
  subMenuType: {
    type: String as PropType<'line' | 'float'>,
    default: 'float'
  },
  list: {
    type: Array as PropType<MenuConfig[]>,
    default: () => [] as MenuConfig[]
  }
})
const emit = defineEmits(['update:open', 'update:subMenuOpen'])
const route = useRoute()
// SUB MENU
const subMenuShowId = ref(-1)
function hasMenuChildren(item: MenuConfig) {
  return item.children && item.children.length > 0
}
function toggleSubMenu(index: number) {
  if (index === subMenuShowId.value) {
    closeSubMenu()
  } else {
    openSubMenu(index)
  }
}
function openSubMenu(index: number) {
  subMenuShowId.value = index
  emit('update:subMenuOpen', true)
}
function closeSubMenu() {
  subMenuShowId.value = -1
  emit('update:subMenuOpen', false)
}
function toggle() {
  emit('update:open', !props.open)
}
function close() {
  emit('update:open', false)
}
function clickSelf(item: MenuConfig) {
  if (!item.is_new_tab) {
    // close()
    if (subMenuShowId.value !== -1) {
      closeSubMenu()
    }
  }
}
function getRoutePath(link: string) {
  return link.replace('{lang}', route.params.lang as string)
}
watch(
  () => route.path,
  () => {
    close()
  }
)
watch(
  () => props.open,
  (val) => {
    if (!val) {
      document.body.classList.remove('ban-scroll')
    } else {
      document.body.classList.add('ban-scroll')
    }
  }
)
</script>
<style lang="scss" scoped>
.com-nav-arrow,
.com-nav-outside {
  width: 14px;
  height: 10px;
  flex-shrink: 0;
  fill: #ffffff;
  margin: 2px 0 0 5px;
}
.com-menu {
  flex: 1;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.com-menu-list {
  padding: 0 25px 1px;
}
.com-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
}
.com-menu-item-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  transition: 0.3s;
  &.router-link-exact-active,
  &.router-link-active,
  &.open {
    color: #ffba00;
    .com-nav-arrow,
    .com-nav-outside {
      fill: #ffba00;
    }
    .com-nav-arrow {
      transform: rotate(0);
    }
  }
}

.com-menu-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #26282d;
}
.com-menu-icon {
  width: 60px;
  height: 60px;
}
.com-menu-close {
  width: 31px;
  height: 30px;
}
// media hover
@media not all and (hover: none) {
  .com-menu-item-link:hover {
    color: #ffba00;
    .com-nav-arrow,
    .com-nav-outside {
      fill: #ffba00;
    }
  }
}

@media not all and (min-width: 1024px) {
  .com-nav-outside,
  .com-nav-arrow {
    width: 32px;
    height: 24px;
  }
  .com-menu {
    position: fixed;
    top: 120px;
    left: 110%;
    z-index: 1;
    width: 100%;
    height: calc(100vh - 120px);
    opacity: 0;
    transform: translateX(100%);
    transition: 0.5s ease;
    transition-property: opacity, transform;
    background-color: rgba(0, 0, 0, 1);
    &.open {
      opacity: 1;
      transform: translateX(-110%);
    }
  }
  .com-menu-list {
    position: relative;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    background-color: #14161b;
  }
  .com-menu-item {
    width: 100%;
    font-size: 36px;
    min-height: 100px;
    line-height: 100px;
  }
  .com-menu-item-link {
    width: 100%;
    padding: 0 60px;
    &.router-link-exact-active,
    &.router-link-active,
    &.open {
      background-color: #363a41;
      .com-nav-arrow,
      .com-nav-outside {
        fill: #ffba00;
      }
    }
  }
  .com-sub-menu-list .com-menu-item-link {
    &.router-link-exact-active,
    &.router-link-active {
      background-color: transparent;
    }
  }
  .com-nav-arrow,
  .com-nav-outside {
    fill: var(--theme-text-light);
    margin: 8px 0 0 12px;
  }
  .com-sub-menu {
    background-color: var(--theme-bg-light-opacity);
    .com-menu-item-link {
      padding: 0 2.5rem;
    }
    .com-menu-item {
      min-height: 2.75rem;
      line-height: 2.75rem;
    }
  }
}
@media screen and (min-width: 1024px) {
  .com-menu-container {
    flex: 1;
  }
  .com-menu-list {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .com-menu-item {
    margin: 0 30px;
  }
  .com-menu-item-link {
    position: relative;
    &.router-link-exact-active,
    &.router-link-active,
    &.open {
      .com-menu-name {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -31px;
          transform: translate(-50%, 0);
          width: 97%;
          height: 3px;
          background-color: var(--theme-primary);
        }
      }
    }
  }
  /* --- 子列表 --- */
  .com-sub-menu {
    &.line {
      position: fixed;
      left: 0;
      top: var(--com-header-height);
      width: 100%;
      height: 50px;
      background-color: #333;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .com-sub-menu-list {
        display: flex;
        align-items: center;
        height: 100%;
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 184px;
      }

      .com-menu-item-link {
        white-space: nowrap;
        &.router-link-exact-active,
        &.router-link-active {
          background-color: transparent;
          color: #ffba00;
          &:after {
            display: none;
          }
        }
      }
    }
    &.float {
      position: absolute;
      left: 0;
      top: calc(100% + 1rem);
      width: fit-content;
      height: auto;
      min-width: 50px;
      text-align: center;
      background-color: #333;
      .com-menu-item {
        margin: 0;
        height: 38px;
        line-height: 1;
      }
      .com-menu-item-link {
        width: 100%;
        height: 100%;
        justify-content: center;
      }
    }

    .com-menu-item-link {
      &.router-link-exact-active,
      &.router-link-active {
        background-color: #ffba00;
        color: #fff;
        &:after {
          display: none;
        }
      }
    }
  }
  // 隐藏移动端按钮
  .com-menu-button {
    display: none !important;
  }
}
</style>
