<template>
  <div
    v-if="categories.length > 1"
    v-clickout="close"
    :class="['app-search-category', isOpen && 'open']"
    @click="toggle"
  >
    <span class="app-search-category-selected">{{ formatCategory(modelValue) }}</span>
    <svg class="app-search-category-arrow" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 6.13326L0.990613 0L0 0.933373L7.5 8L15 0.933373L14.0094 0L7.5 6.13326Z"
        fill="#C8CCD3"
      />
    </svg>
    <ul class="app-search-category-list" @click.stop>
      <li
        v-for="category in newCategoryList"
        :key="category"
        :class="['app-search-category-item', modelValue === category && 'selected']"
        @click="change(category)"
      >
        {{ formatCategory(category) }}
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'
const store = useStore()
const { formatTransify } = store
const props = defineProps({
  modelValue: { type: Number, default: 0 },
  categories: { type: Array as PropType<number[]>, default: () => [] }
})
const emit = defineEmits(['update:modelValue'])

const isOpen = ref(false)

const close = () => {
  isOpen.value = false
}
const toggle = () => {
  isOpen.value = !isOpen.value
}
const newCategoryList = computed(() => {
  if (props.categories.length > 1) {
    return [0, ...props.categories]
  }
  return props.categories
})
const change = (category: number) => {
  emit('update:modelValue', category)
  close()
}
const formatCategory = (category: number) => {
  switch (category) {
    case 1:
      return formatTransify('UGC_OFFICIAL_WEBSITE_DOCUMENTS')
    case 2:
      return formatTransify('UGC_OFFICIAL_WEBSITE_TUTORIAL')
    default:
      return formatTransify('UGC_OFFICIAL_WEBSITE_SEARCH_ALL')
  }
}
</script>
<style scoped lang="scss">
.app-search-category-selected {
  text-transform: capitalize;
}
@media not all and (min-width: 1024px) {
  .app-search-category {
    display: flex;
    align-content: center;
    justify-content: space-between;
    position: relative;
    width: 230px;
    height: 36px;
    font-size: 24px;
    color: #c8ccd3;
    border: 1px solid #4d5158;
    background-color: #363a41;
    padding: 0 12px;
    &.open {
      .app-search-category-arrow {
        transform: rotate(180deg);
      }
      .app-search-category-list {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  .app-search-category-selected {
    width: 82%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .app-search-category-list {
    position: absolute;
    top: calc(100% + 4px);
    left: -1px;
    width: calc(100% + 4px);
    max-height: calc(42px * 5);
    background-color: #363a41;
    border: 1px solid #4d5158;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    text-transform: capitalize;
  }
  .app-search-category-item {
    height: 42px;
    padding: 4px 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.selected {
      background-color: #2b2d32;
    }
  }
  .app-search-category-arrow {
    width: 15px;
    transition: rotate 0.3s;
    flex-shrink: 0;
  }
}
@media screen and (min-width: 1024px) {
  .app-search-category {
    display: flex;
    align-content: center;
    justify-content: space-between;
    position: relative;
    width: 168px;
    height: 20px;
    font-size: 14px;
    color: #c8ccd3;
    border: 1px solid #4d5158;
    background-color: #363a41;
    padding: 0 8px;
    cursor: pointer;
    &.open {
      .app-search-category-arrow {
        transform: rotate(180deg);
      }
      .app-search-category-list {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  .app-search-category-selected {
    width: 82%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .app-search-category-list {
    position: absolute;
    top: calc(100% + 4px);
    left: -1px;
    width: calc(100% + 4px);
    max-height: calc(24px * 5);
    background-color: #363a41;
    border: 1px solid #4d5158;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    text-transform: capitalize;
  }
  .app-search-category-item {
    height: 24px;
    padding: 2px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.selected {
      background-color: #2b2d32;
    }
  }
  .app-search-category-arrow {
    width: 10px;
    transition: rotate 0.3s;
    flex-shrink: 0;
  }
}
</style>
